const channel = new BroadcastChannel('test-channel');

channel.onmessage = (msg) => console.log("outer channel onMessage", msg);

console.log("outer loaded");

let proxy;

document.getElementById('button')?.addEventListener('click', () => {
    // for safari response_mode = web_message
    // otherwise response_mode=form_post
    proxy = window.open(`https://appleid.apple.com/auth/authorize?response_type=code+id_token&response_mode=web_message&client_id=net.glozer.oidc-test&redirect_uri=${window.location}&nonce=abc&scope=name+email`);
});


window.addEventListener('message', (msg) => {
    if (proxy != null) {
        console.log("close window");
        proxy.close();
    }
    console.log(msg);
    let text = document.createElement('p');
    text.innerText = msg.data;
    document.body.appendChild(text);
}, false);
